
<table class="upload-table">
  <thead>
    <th style="width: 25%;">File Name</th>
    <th style="width: 60%;">Status</th>
    <th style="width: 15%; text-align:center;">Actions</th>
  </thead>
  <tbody style="height: 50px;">
    <td>{{fileDetail.fileName}}</td>
    <td>
      <mat-card-content>
        <div style="display: inline-flex;width: 100%;justify-content: space-between;">
          <div>{{fileDetail.state !='Cancelled'  ? fileDetail.state:'' }}</div>
          <div *ngIf="fileDetail.state==='In Progress' || fileDetail.state=== 'Completed'"
            [ngStyle]="{'color': fileDetail.state=='Completed' ? '#37A510' : '#DE9200' }">{{fileDetail.progress}} %</div>
        </div>
        <section class="example-section" class="upload-progress"
          *ngIf="fileDetail.state==='In Progress' || fileDetail.state=== 'Completed'"
          [ngStyle]="{'color': fileDetail.state=='Completed' ? '#37A510' : '#DE9200' }">
          <mat-progress-bar mode="determinate"
            [ngStyle]="{'border-color': fileDetail.state=='Completed' ? '#37A510' : '#DE9200' }"
            value="{{fileDetail.progress}}"></mat-progress-bar>
        </section>
      </mat-card-content>
    </td>
    <td style="display: flex;justify-content: center;">
      <mat-icon class="ml-2" (click)="cancelUpload()"  *ngIf="fileDetail.state==='In Progress'">cancel</mat-icon>
      <mat-icon class="ml-2" (click)="resetStage()"    *ngIf="fileDetail.state==='Failed'|| fileDetail.state==='Cancelled' || fileDetail.state==='' ">delete</mat-icon>
      <mat-icon class="ml-2" (click)="uploadFile()" *ngIf="fileDetail.state==='Failed' || fileDetail.state==='Cancelled'">refresh</mat-icon>
    </td>
  </tbody>
</table>
