import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { TimeFormatService } from 'src/app/services/timeformat/time-format.service';

@Pipe({
  name: 'timeFormat',
  pure: false
})
export class TimeFormatPipe implements PipeTransform, OnDestroy {

  private currentFormat: 'utc' | 'local' ='local';
  private subscription: Subscription;

  constructor(private timeFormatService: TimeFormatService, private cdr: ChangeDetectorRef) {
    this.subscription = this.timeFormatService.currentFormat$.subscribe(format => {
      this.currentFormat = format;
    });
  }

  transform(value: string): string {    
    const time = moment.parseZone(value);
    if (this.currentFormat === 'local') {
      return time.format('YYYY-MM-DD HH:mm:ss');
    } else {
      return time.utc().format('YYYY-MM-DD HH:mm:ss');
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}