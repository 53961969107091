import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionDeniedErrorComponent } from './permission-denied-error/permission-denied-error.component';
import { InternalErrorComponent } from './internal-error/internal-error.component';



@NgModule({
  declarations: [
    PermissionDeniedErrorComponent,
    InternalErrorComponent
  ],
  imports: [
    CommonModule
  ],
  exports:[
    PermissionDeniedErrorComponent,
    InternalErrorComponent
  ]
})
export class ErrorModule { }
