import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  private apiFailedErrorSubject = new BehaviorSubject<boolean>(false);
  constructor() { }

  setAPIErrorStatusSubject(value:boolean):void{
    this.apiFailedErrorSubject.next(value);
  }

  getAPIErrorStatusSubject():  BehaviorSubject<boolean>{
    return this.apiFailedErrorSubject;
  }
}
