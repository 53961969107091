import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpClient,
} from '@angular/common/http';
import {
  BehaviorSubject,
  catchError,
  filter,
  finalize,
  Observable,
  switchMap,
  take,
  throwError,
} from 'rxjs';
import { SkyappJWTAuthService } from 'src/app/services/skyapp-jwt-auth/skyapp-jwtauth.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { UtilService } from 'src/app/services/util/util.service';
@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject = new BehaviorSubject<any>(null);



  constructor(
    private skyappJwtAuthSerice: SkyappJWTAuthService,
    private http: HttpClient,
    private oauthService: OAuthService,
    private utilService: UtilService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    // We are checking if the url is our API url or fedproxy url. if it is our URL then only pass the skyapp_jwt and Fedproxy token
    if (!req.url.includes(environment.wssoIssuer)) {
      req = this.addHeaderToRequest(req, 'platform', 'WEB');

      // checking if skyapp token is valid, then add it to header
      if(this.skyappJwtAuthSerice.hasValidSkyAppToken()){
        req = this.addHeaderToRequest(
          req,
          'skyapp_jwt',
          this.skyappJwtAuthSerice.getSkyAppToken()
        );
      }
      

      // checking if fedproxy token is valid, then add it to header
      if (this.oauthService.hasValidAccessToken()) {
        req = this.addHeaderToRequest(
          req,
          'Authorization',
          `Bearer ${this.oauthService.getAccessToken()}`
        );
      }


      return next.handle(req).pipe(
        // handle all the error codes
        catchError((httpErrorResponse) => {
         // Handle specific status codes
         switch (httpErrorResponse.status) {
          case 401:
            if (httpErrorResponse.error.error?.errorCode === 'SKYAPP_JWT_EXPIRED') {
              // return this.handleSkyAppTokenExpiredError(req, next);
              console.error('Unauthorized. Check if you have a  valid skyapp Token');
            }
            break;
          case 403:
            // Handle forbidden errors (403)
            console.error('Access forbidden. Please check your permissions.');
            break;
          case 404:
            // Handle not found errors (404)
            console.error('Resource not found. Please check the URL.');
            // Optionally redirect to a 404 page
            break;
          case 500:
            // Handle internal server errors (500)
            this.utilService.setAPIErrorStatusSubject(true);
            console.error('An internal server error occurred. Please try again later.');
            // Optionally log the error to a server or show a user-friendly message
            break;
          default:
            // Handle other error statuses
            console.error(`Unexpected error: ${httpErrorResponse.status}`);
            break;
        }
        // Propagate the error
        return throwError(httpErrorResponse);
        })
      );
    } else {
      return next.handle(req);
    }
  }



  private addHeaderToRequest(
    req: HttpRequest<any>,
    headerKey: string,
    headerValue: string |null
  ): HttpRequest<any> {
    const headers: Record<string, string> = {};
    if(headerValue!=null){
      headers[headerKey] = headerValue;
    }
    return req.clone({
      setHeaders: headers,
    });
  }

  // private handleSkyAppTokenExpiredError(
  //   req: HttpRequest<any>,
  //   next: HttpHandler
  // ): Observable<HttpEvent<any>> {
  //   if (!this.isRefreshing) {
  //     this.isRefreshing = true;
  //     this.refreshTokenSubject.next(null);
  //     this.skyappJwtAuthSerice.removeSkyAppToken();

  //     return this.skyappJwtAuthSerice.validateUserAndGenerateJWTv2().pipe(
  //       switchMap((newToken: any) => {
  //         if (newToken && newToken.accessToken) {
  //           this.skyappJwtAuthSerice.setSkyAppToken(newToken.accessToken);
  //           this.refreshTokenSubject.next(newToken.accessToken);
  //           req = this.addHeaderToRequest(req,"skyapp_jwt", newToken.accessToken);
  //           req = this.addHeaderToRequest(req,"Authorization", `Bearer ${this.oauthService.getAccessToken()}`);
  //           return next.handle(req);
  //         }

  //         // If token renewal failed, log the user out or handle as needed
  //         return throwError('Token renewal failed.');
  //       }),
  //       catchError((error) => {
  //         // Token renewal error, log the user out or handle as needed
  //         return throwError('Token renewal error.');
  //       }),
  //       finalize(() => {
  //         this.isRefreshing = false;
  //       })
  //     );
  //   } else {
  //     // If already refreshing, wait until refresh completes and retry request
  //     return this.refreshTokenSubject.pipe(
  //       filter((token) => token != null),
  //       take(1),
  //       switchMap((token) => {
  //         req = this.addHeaderToRequest(req,"skyapp_jwt", token);
  //         req = this.addHeaderToRequest(req,"Authorization", `Bearer ${this.oauthService.getAccessToken()}`);
  //         return next.handle(req);
  //       })
  //     );
  //   }
  // }

  
}
