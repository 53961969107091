import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FlightLeg } from 'src/app/models/flight-leg-interface';
import { BlobServiceClient } from '@azure/storage-blob';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { NotificationDialogComponent } from 'src/app/shared/components/notification-dialog/notification-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FileUploadContainer } from 'src/app/models/upload-file-interface';
import { UploadFlightLegDialogComponent } from 'src/app/modules/home/components/upload-flight-leg-dialog/upload-flight-leg-dialog.component';
import { FlightLegsService } from '../flight-legs/flight-legs.service';

export interface UploadStatus2 { // interface to track the Status of file.
  fileName: string;
  progress: number;
  fileContainer: FileUploadContainer; // ongoing file details 

}
@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  private uploadProgressSubject = new Subject<{ [key: string]: FileUploadContainer }>(); // File's progress used in modal, % of file complete.
  private uploadLegStatusSubject = new Subject<{[key : string]: FileUploadContainer}>(); // FlightLeg Status used in flight leg table to show state
  private uploadStatus: { [key: string]: FileUploadContainer } ={}; // Object to store all the UploadFile status and their states.

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  
  isUploadSuccess = new BehaviorSubject<any>({isSuccess: false, fileName:""});

  constructor(private http: HttpClient,public dialog: MatDialog, private flightLegsService: FlightLegsService) {}

  generateSasToken(action:string,containerName:string,directoryPath: string): Observable<any> { // generate Sas Token used for upload and download.
    return this.http.get<any>(
      `${environment.baseUrl}/api/file-storage/generate-sas-token/${action}?containerName=${containerName}&directoryPath=${directoryPath}`
    );
  }

 upload(fileContainer: FileUploadContainer, selectedFlightLeg:FlightLeg, sasToken:string, dialogOpen:any ,identifier:string ){
        if (selectedFlightLeg!=null) {
          let bloburl = `${selectedFlightLeg.bemsId}/${fileContainer.fileName}`; 
          console.log("blob url: " + bloburl);
          const blobServiceClient = new BlobServiceClient(
            `${environment.storageAccountBaseUrl}/${sasToken}`
          );
          const containerClient = blobServiceClient.getContainerClient(environment.rawDataContainerName);
          const blockBlobClient = containerClient.getBlockBlobClient(bloburl);
          fileContainer.blockBlobClient = blockBlobClient;
          fileContainer.cancelTokenSource = new AbortController();
          fileContainer.progress=0;
          fileContainer.state= "In Progress";
          this.uploadStatus[identifier] = fileContainer;


          this.uploadLegStatusSubject.next(this.uploadStatus);
          fileContainer.blockBlobClient.uploadData(fileContainer.file, {
            abortSignal: fileContainer.cancelTokenSource.signal,
            blobHTTPHeaders: { blobContentType: fileContainer.file.type },
            blockSize: 4 * 1024 * 1024,
            onProgress: (ev: any) => {
              const progress = Math.floor((ev.loadedBytes / fileContainer.file.size) * 100);
              fileContainer.progress=progress;
              this.uploadStatus[identifier] = fileContainer;
              this.uploadProgressSubject.next(this.uploadStatus);
              if (progress == 100) {
                let tempFlightLeg: FlightLeg = { ...selectedFlightLeg };
                tempFlightLeg.flightLegStatus = 'Uploaded Via Web';
                tempFlightLeg.rawDataUploaded=true;
                this.flightLegsService.updateFlightLeg(tempFlightLeg)
                  .subscribe((result) => {
                    this.dialog.open(NotificationDialogComponent, {
                      data: { status: true, flightleg: selectedFlightLeg, dialog: dialogOpen },
                      minHeight: '30px',
                      maxHeight: '30px',
                      height: '30px',
                      position: { top: 'calc(var(--height-10)*10)' }
                    });
                    selectedFlightLeg.flightLegStatus = 'Uploaded Via Web';
                    selectedFlightLeg.rawDataUploaded=true;
                    fileContainer.progress=100;
                    fileContainer.state= "Completed";
                    this.uploadProgressSubject.next(this.uploadStatus);
                    this.uploadLegStatusSubject.next(this.uploadStatus);
                  },
                    (error) => {
                      // selectedFlightLeg.flightLegStatus = 'Created';
                      console.log(error);
                      this.dialog.open(NotificationDialogComponent, {
                        data: { status: false, flightleg: selectedFlightLeg, dialog: dialogOpen },
                        minHeight: '30px',
                        maxHeight: '30px',
                        height: '30px',
                        position: { top: 'calc(var(--height-10)*10)' },
                        hasBackdrop: false
                      });
                      fileContainer.progress=0;
                      fileContainer.state = 'Failed';
                      this.uploadStatus[identifier] = fileContainer;
                      this.uploadProgressSubject.next(this.uploadStatus);
                      this.uploadLegStatusSubject.next(this.uploadStatus);
                    });
              }
            }
          }).catch((error:any)=>{
            if (error.name === 'AbortError') {
              fileContainer.progress=0;
              fileContainer.state='Cancelled';

              this.uploadStatus[identifier] = fileContainer;
              this.uploadProgressSubject.next(this.uploadStatus);
              this.uploadLegStatusSubject.next(this.uploadStatus);
            } else {
              this.dialog.open(NotificationDialogComponent, {
                data: {  status:false,flightleg:selectedFlightLeg,dialog:dialogOpen },
                minHeight: '30px',
                maxHeight:'30px',
                height:'30px',
                position: { top: 'calc(var(--height-10)*10)' },
                hasBackdrop:false
              });
              fileContainer.progress=0;
              fileContainer.state='Failed'
              this.uploadStatus[identifier] = fileContainer;
              this.uploadProgressSubject.next(this.uploadStatus);
              this.uploadLegStatusSubject.next(this.uploadStatus);
            }
          });
        }
  }

  // saveMetadata(fileObj: any,bemsId:string, bloburl: string,flightLegId:string, identifier:string): Observable<any> {
  //   let fileMetadataObj = {
  //     url: `${environment.baseUrl}/${environment.containerName}/${bloburl}`,
  //     fileSize: fileObj.file.size,
  //     fileType: fileObj.fileType,
  //     name: fileObj.name,
  //   };
  //   // const headers = { 'Content-Type': 'application/json' };
  //   // const body = JSON.stringify(fileMetadataObj);
  //   this.uploadStatus[flightLegId +""+identifier] = { fileName: fileObj.name, progress: 0, state: 'In Progress',fileContainer:fileObj.file };

  //   return this.http.post(`${environment.baseUrl}/api/upload-ms/bemsId/${bemsId}/save`, fileMetadataObj, this.httpOptions);
  // }

  
  // saveMetadata2(fileObj: any,selectedFlightLeg:FlightLeg, bloburl: string): Observable<any> {
  //   let fileMetadataObj = {
  //     uploadedPath: `${environment.baseUrl}/${environment.containerName}/${bloburl}`,
  //     fileSize: fileObj.file.size,
  //     fileName: fileObj.name,
  //     flightLegId: selectedFlightLeg.flightLegId,
  //     userId: selectedFlightLeg.userId
  //   };
  //   // const headers = { 'Content-Type': 'application/json' };
  //   // const body = JSON.stringify(fileMetadataObj);

  //   return this.http.post(`${environment.baseUrl}/api/file/saveMetadata`, fileMetadataObj, this.httpOptions);
  // }

  getFileProgressUpdates(): Subject<{ [key: string]: FileUploadContainer }> {
    return this.uploadProgressSubject;
  }

  getLegStatusUpdates(): Subject<{ [key: string]: FileUploadContainer }> {
    return this.uploadLegStatusSubject;
  }

  getUploadStatusByKey(key: string): FileUploadContainer | null {
    return this.uploadStatus[key];
  }

  getUploadStatusByMatchingPrefix(keyString : string) : FileUploadContainer[]  { //get the all upload status starting with key combination "leguid" and "dataType" 
    const filteredEntries = Object.entries(this.uploadStatus).filter(([key]) => key.startsWith(keyString));
    return (filteredEntries.length > 0)
      ? filteredEntries.map(([key, value]) => value as FileUploadContainer) // Cast to UploadStatus
      : [];
  }

  

  removeUploadStatus(key:string):void{
    delete this.uploadStatus[key];
    this.uploadProgressSubject.next(this.uploadStatus);
    this.uploadLegStatusSubject.next(this.uploadStatus);
  }
  
  cancelUpload(key:string):void{
    this.uploadStatus[key].cancelTokenSource.abort();
  }


  async showUploadDialog( flightLeg: FlightLeg){
    this.dialog.open(UploadFlightLegDialogComponent,{
      data :{
        flight:flightLeg,
      }, 
          maxHeight: '90vh',
          maxWidth: '50vw',   
          minHeight: '300px',
          minWidth: '50vw',
          height: '70vh',
          width: '50vw',
          // width: '400px',
          // height: '300px',
          position: { top: 'calc(var(--height-10)*13)' }
    });
    
  }
  
}



