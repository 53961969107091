export const environment = {
  rawDataContainerName: 'raw-data',
  extractedDataContainername:'extracted-data',
  processedDataContainerName:'datawarehouse',
  storageAccountBaseUrl:'https://dev.sky.digitalaviationservices.com',
  baseUrl: 'https://dev.sky.digitalaviationservices.com',
  wssoIssuer:'https://auth.test.fedb.digitalaviationservices.com',
  wssoClientId: 'skyapp_dev',
  // wssoIDP:'urn:wsso:preprod:daf-jit-v2:uat',
  wssoIDP:'urn:wsso:ad:preprod:daf-jit-v2'
};
