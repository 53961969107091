<div style="height: 90%;">
<div class="dialog-header">
    <div>
        Upload File to {{ selectedFlightLeg.legGUID }}
        <span>({{ selectedFlightLeg.departureAirportCode }} -
            {{ selectedFlightLeg.arrivalAirportCode }})</span>
    </div>
    <mat-icon style="align-content: flex-end" (click)="onNoClick()">close</mat-icon>
</div>
<div class="boarder-header"></div>
<div style="padding: 10px; height: inherit;">
    <div *ngIf="this.fileObj == null" style="display:grid; justify-content: center; height: inherit;">
        <div class="container-center">
            <img class="m-2" src="../../../../../assets/boeing/upload-file.png" />
            No files added/uploaded yet.
            <div class="m-2">
                <input #fileInput hidden="true" type="file" onclick="this.value=null" (change)="fileBrowseHandler($event)"accept=".zip" />
                <button mat-flat-button class="upload-button" (click)="fileInput.click()">Browse for file</button>
            </div>
        </div>
    </div>
    <div *ngIf="this.fileObj != null">
        <app-upload [uploadType]="uploadType" [flightLeg]="selectedFlightLeg" [fileDetail]="fileObj" (fileChange)="onfileChange()"></app-upload>
    </div>
</div>
<div class="dialog-footer">
    <button class="mr-2 ml-2" mat-stroked-button
        [ngClass]="disableButton ? 'disabled-button' : ''"
        [disabled]="disableButton" (click)="startupload()">
        Upload
    </button>
    <button class="mr-2 ml-2" mat-stroked-button (click)="onNoClick()">
        Cancel
    </button>
</div>
</div>