import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { SkyappJWTAuthService } from 'src/app/services/skyapp-jwt-auth/skyapp-jwtauth.service';

@Component({
  selector: 'app-logout-view',
  templateUrl: './logout-view.component.html',
  styleUrls: ['./logout-view.component.scss']
})
export class LogoutViewComponent  {
  countdown: number = 3; // Set the initial countdown time in seconds

  constructor(private router: Router, private skyappJwtAuthSerice: SkyappJWTAuthService ){
   

   
  }
  ngOnInit() {
     //  this.startCountdown();
    this.skyappJwtAuthSerice.logout();
    localStorage.clear();
    sessionStorage.clear();
  }

  // startCountdown() {
  //   const countdownInterval = setInterval(() => {
  //     this.countdown -= 1;
  //     if (this.countdown <= 0) {
  //       this.skyappJwtAuthSerice.logout();
  //       clearInterval(countdownInterval);
  //       // Redirect to another page here using Angular Router
  //       this.router.navigate(['']);
  //     }
  //   }, 1000);
  // }

}
