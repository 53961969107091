  <div class="menu-container">
    <p-menubar [model]="items">
      <ng-template pTemplate="start">
        <div style="display:flex; margin-left: 2px; padding-left: 10px; padding-right: 10px; justify-content: space-between; height: 100%;">
          <div style="display: flex;align-items: center; height: 100%;">
            <img src="../../../../assets/boeing/Logo.svg" style="width: 30px; margin-right: 8px;" />
            <div class="app-name">
              Flight Sensor Pro
            </div>
          </div>
          <div style="display: flex;align-items: center; height: 100%;" *ngIf="localStorage && localStorage.getItem('skyappJWT') != ''">
            <div class="bell-icon">
              <!-- <mat-icon class="mr-2 ml-2 white-icon">notifications</mat-icon> -->
              <img src="../../../../assets/boeing/bell-icon.svg" alt="" class="mr-2 ml-2 white-icon">
              <p-menu #usermenu [popup]="true" [model]="userItems"></p-menu>
            </div>
            <div *ngIf="isAdminOrSuperAdmin" class="bell-icon">
              <!-- <mat-icon class="vector-icon mr-2 ml-2 white-icon cursor-pointer-enabled"
                (click)="vectormenu.toggle($event)">settings</mat-icon> -->
                <img src="../../../../assets/boeing/gear-icon.svg" alt="" class="mr-2 ml-2 white-icon" (click)="vectormenu.toggle($event)">
              <p-menu #vectormenu [popup]="true" [model]="vectorItems"></p-menu>
            </div>
            <div class="bell-icon">
              <img src="../../../../assets/boeing/timezone.svg" alt="" class="mr-2 ml-2 white-icon" (click)="timezonemenu.toggle($event)">
              <p-menu #timezonemenu [popup]="true" [model]="timeZoneItems"></p-menu>
            </div>
            <div class="bell-icon">
              <!-- <mat-icon class="user-icon mr-2 ml-2 white-icon person-icon" (click)="usermenu.toggle($event)">person
              </mat-icon> -->
              <img src="../../../../assets/boeing/user-icon.svg" alt="" class="mr-2 ml-2 white-icon" (click)="usermenu.toggle($event)">
              <p-menu #usermenu [popup]="true" [model]="userItems" class="menu-onhover menu-onhover-signout signout-text">
              </p-menu>
            </div>
          </div>
        </div>
      </ng-template>
    </p-menubar>
    
    <p-dialog header="Terms of Use" [(visible)]="display" [modal]="true"
      [breakpoints]="{ '2560px': '60vw', '1199px': '60vw', '1024px': '60vw', '768px': '70vw',  '575px': '90vw' }">
      <ng-template pTemplate="header">
        <div class="dialog-header">
          <span class="dialog-title">Terms of Use</span>
        </div>
      </ng-template>
      <div class="dialog-content" [innerHTML]="processedTermsOfUse"></div>
      <ng-template pTemplate="footer">
        <div class="dialog-actions">
          <button pButton type="button" label="Understood" (click)="closeDialog()" class="custom-button"></button>
        </div>
      </ng-template>
    </p-dialog>
  </div>
  

    
  
  