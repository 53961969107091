import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SkyappJWTAuthGuard } from './guards/skyapp-jwt/skyapp-jwtauth.guard';
import { PermissionDeniedErrorComponent } from './modules/error/permission-denied-error/permission-denied-error.component';
import { FlightLegListComponent } from './modules/home/components/flight-leg-list/flight-leg-list.component';
import { HomeComponent } from './modules/home/components/home/home.component';
import { LoginViewComponent } from './modules/login/components/login-view/login-view.component';
import { LogoutViewComponent } from './modules/login/components/logout-view/logout-view.component';

const routes: Routes = [
  
  // { path: 'login', component: LoginViewComponent},
  {path: 'logout', component:LogoutViewComponent},
  { path: 'home', component: HomeComponent ,canActivate: [SkyappJWTAuthGuard]},
  // { path: 'forbidden', component: PermissionDeniedErrorComponent},
  { path: '', redirectTo: 'home', pathMatch: 'full' }

  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
