 <div>
    <div *ngIf="flightLegs.length > 0; else thenBlock">
        <div class="flights-table">
            <div class="card" style="margin-bottom: 20px;">
                <div class="flex">
                    <div class="table-header" >
                    Flight Legs
                    </div>
                    <div class="input-container ml-auto" style="width: 23.44vw; height: 100%; ">
                        <input matInput class="search-input" style="background: white; " type="text" (input)="applyFilterGlobal($event, 'contains')" placeholder="Search by Flight Number">
                        <div class="input-icon" >
                            <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.2656 9.52795H9.61682L9.38688 9.30635C10.3723 8.15734 10.8815 6.58976 10.6023 4.92369C10.2163 2.64208 8.3111 0.820077 6.01173 0.541031C2.53804 0.114255 -0.385452 3.03603 0.041574 6.50769C0.320784 8.80571 2.14386 10.7098 4.42681 11.0955C6.09385 11.3746 7.66235 10.8657 8.81204 9.88086L9.03376 10.1107V10.759L12.5239 14.2471C12.8606 14.5836 13.4108 14.5836 13.7475 14.2471C14.0842 13.9106 14.0842 13.3607 13.7475 13.0242L10.2656 9.52795ZM5.33834 9.52795C3.29354 9.52795 1.64292 7.8783 1.64292 5.8347C1.64292 3.79109 3.29354 2.14144 5.33834 2.14144C7.38314 2.14144 9.03376 3.79109 9.03376 5.8347C9.03376 7.8783 7.38314 9.52795 5.33834 9.52795Z" fill="#009BDE"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bordered-table">
                <p-table #dt
                [value]="displayflightLegs"
                [rows]="10"
                [showCurrentPageReport]="true"
                [scrollable]="true" scrollHeight="65vh"
                dataKey="legGUID"
                [globalFilterFields]="['flightNumber']"
                (sortFunction)="customSort($event)" [customSort]="true"
                >
                    <ng-template pTemplate="header" class="p-datatable-thead" style="color:red !important">
                            <tr>
                            <th>Flight Leg GUID</th>
                            <th [ngClass]="{'sort-active': activeSortField === 'fr24FlightId'}" pSortableColumn="fr24FlightId">FR24 Flight ID <p-sortIcon field="fr24FlightId" ></p-sortIcon></th>
                            <th [ngClass]="{'sort-active': activeSortField === 'flightNumber'}" pSortableColumn="flightNumber">Flight Number <p-sortIcon field="flightNumber"></p-sortIcon></th>
                            <th [ngClass]="{'sort-active': activeSortField === 'departureAirportCode'}" pSortableColumn="departureAirportCode">Dep. Airport <p-sortIcon field="departureAirportCode"></p-sortIcon></th>
                            <th [ngClass]="{'sort-active': activeSortField === 'scheduledDepartureDate'}" pSortableColumn="scheduledDepartureDate">Dep. Date <p-sortIcon field="scheduledDepartureDate"></p-sortIcon></th>
                            <th [ngClass]="{'sort-active': activeSortField === 'arrivalAirportCode'}" pSortableColumn="arrivalAirportCode">Arr. Airport <p-sortIcon field="arrivalAirportCode"></p-sortIcon></th>
                            <th [ngClass]="{'sort-active': activeSortField === 'scheduledArrivalDate'}" pSortableColumn="scheduledArrivalDate">Arr. Date <p-sortIcon field="scheduledArrivalDate"></p-sortIcon></th>
                            <th [ngClass]="{'sort-active': activeSortField === 'flightLegStatus'}" pSortableColumn="flightLegStatus">Status <p-sortIcon field="flightLegStatus"></p-sortIcon></th>  
                            <th>Actions</th>
                        </tr>
                    </ng-template>
    
                    <ng-template pTemplate="sorticon" let-sortOrder>
                        <span *ngIf="sortOrder === 1" > <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="arrow_drop_up" class="sort-icon-class"></mat-icon></span>
                        <span *ngIf="sortOrder === -1" > <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="arrow_drop_down" class="sort-icon-class"></mat-icon></span>
                        <span *ngIf="sortOrder === 0" > <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="arrow_drop_up" class="sort-icon-class"></mat-icon></span>
                    </ng-template>
    
                    <ng-template pTemplate="body" let-flightLeg class="p-datatable-tbody">
                        <tr style="cursor: pointer; " (click)="loadFlightDetails(flightLeg)" [class]="{'row-error': flightLeg.flightLegStatus.includes('Error')}">
                            <td class="legGUID" data-info="Identification"><abbr title="{{ flightLeg.legGUID }}" style="text-decoration: none;">{{ flightLeg.legGUID }}</abbr></td>
                            <td>{{ flightLeg.fr24FlightId ?  flightLeg.fr24FlightId : 'Not Available' }}</td>
                            <td>{{ flightLeg.flightNumber }}</td>
                            <td><abbr title="{{flightLeg.departureAirportName}}" style="text-decoration: none;">{{ flightLeg.departureAirportCode }}</abbr></td>
                            <td><abbr title="{{timeFormatTooltip}}" style="text-decoration: none;">{{ flightLeg.scheduledDepartureDate | timeFormat | date:'medium'}}</abbr></td>
                            <td><abbr title="{{ flightLeg.arrivalAirportName }}" style="text-decoration: none;">{{ flightLeg.arrivalAirportCode }}</abbr></td>
                            <td><abbr title="{{timeFormatTooltip}}" style="text-decoration: none;">{{ flightLeg.scheduledArrivalDate | timeFormat | date:'medium'}}</abbr></td>
                            <td>{{ flightLeg.flightLegStatus }}</td>
                            <td (click)="showUploadDialog($event,flightLeg)" style="border: 0px;">
                                <div *ngIf="!flightLeg.rawDataUploaded;">
                                    <ng-container *ngIf="statuses[flightLeg.legGUID+'-UPLOAD'] == null || statuses[flightLeg.legGUID+'-UPLOAD'] == 'Cancelled'; else inProgressOrFailed">
                                        <img src="../../../../../assets/boeing/upload-icon.svg" alt="upload">
                                    </ng-container>
                                    <ng-template #inProgressOrFailed>
                                        <img *ngIf="statuses[flightLeg.legGUID+'-UPLOAD'] == 'In Progress'" src="../../../../../assets/boeing/upload-processing.svg" alt="upload">
                                        <mat-icon *ngIf="statuses[flightLeg.legGUID+'-UPLOAD'] == 'Failed'" style="color: red;">report</mat-icon>
                                    </ng-template>
                                </div>
                            </td>
                        </tr>       
                    </ng-template>
                </p-table>
                <div class=" footer-class flex align-items-center justify-content-end" style="border-top:1px solid var(--Stroke, #CFCFCF);">
                    <span class="mx-1 text-color" style="font-size: var(--font-size-17);">Rows per page: </span>
                    <p-dropdown [options]="[10, 25, 50]" (onChange)="itemChange($event)" [(ngModel)]="rows" (ngModelChange)="first = 0" class="onHoverColor-disabled onHoverColor-disabled-selectedColumn"></p-dropdown>
                    <p-paginator [first]="first" [rows]="rows" [totalRecords]="totalRecords" (onPageChange)="onPageChange($event)" [showCurrentPageReport]="true"
                        currentPageReportTemplate="{first} - {last} of {totalRecords}" [showPageLinks]="false"  ></p-paginator>
                </div>
            </div>
        </div>
    </div>
    <ng-template #thenBlock>
        <div *ngIf="isAPIFailed && !isLoading; else noData">
            <app-internal-error></app-internal-error>
        </div>
        <ng-template #noData >
            <div *ngIf="!isLoading" class="error-container">
                <div class="no-data">
                    <img src="../../../../../assets/boeing/no-data.svg" alt="">
                </div>
                <div class="content-class">
                    <p style="font-weight: 700; color:#263746;">No flight leg to show here,</p>
                    <p style="color: #707B85">In case you have already uploaded your flight leg through mobile app and not showing here, please reach out to <a href="mailto:FlightSensorProServices@boeing.com" class="email-link">FlightSensorProServices@boeing.com</a> for assistance.</p>
                </div>
            </div>
        </ng-template>
    </ng-template> 
</div>
