import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FlightLeg } from 'src/app/models/flight-leg-interface';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileUploadService} from 'src/app/services/file-upload/file-upload.service';
import { UploadComponent } from 'src/app/shared/components/upload/upload.component';
import { FileUploadContainer } from 'src/app/models/upload-file-interface';

@Component({
  selector: 'app-upload-flight-leg-dialog',
  templateUrl: './upload-flight-leg-dialog.component.html',
  styleUrls: ['./upload-flight-leg-dialog.component.scss'],
})
export class UploadFlightLegDialogComponent implements OnInit {
  @ViewChild(UploadComponent) uploadComponent!: UploadComponent;
  selectedFiles!: FileList;
  files: any[] = [];
  filesSelected: File[] = [];
  uploadFilesContainer: any[] = [];
  stage: string = 'select-files';
  disableButton: boolean = true;
  fileObj: FileUploadContainer | null = null;
  selectedFile!: File;
  selectedFlightLeg!: FlightLeg;
  uploadStatus!: FileUploadContainer[];
  sasToken: string = '';
  uploadType = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UploadFlightLegDialogComponent>,
    public uploadService: FileUploadService
  ) {}

  ngOnInit(): void {
    this.uploadType = 'RawSensorData';
    this.selectedFlightLeg = this.data.flight;
    this.uploadStatus = this.uploadService.getUploadStatusByMatchingPrefix(`${this.selectedFlightLeg.legGUID}-UPLOAD-${this.uploadType}`);
    //If length>0 then there is a file upload in progress or failed and ready to retry
    if (this.uploadStatus.length> 0) {
      this.disableButton = true;
      this.fileObj=this.uploadStatus[0];
    }
  }
  
  onNoClick(): void {
    this.dialogRef.close();
  }

  fileBrowseHandler($event: any) {
    this.selectedFile = $event.target.files[0];
    this.fileObj = {
      file: this.selectedFile,
      fileName: this.selectedFile.name,
      fileType: this.selectedFile.name.split('.').pop(),
      progress: 0,
      state: '',
      cancelTokenSource: null,
      blockBlobClient: null,
    };
    this.disableButton = false;
  }

  async startupload() {
    if (this.fileObj != null) {
      this.uploadComponent.uploadFile();
      this.disableButton = true;
    }
  }

  // this will be triggered when the selected file is removed using the rest icon. We are using @Output and event emitter for this functionality
  onfileChange() {
    this.fileObj = null;
  }
}
