
<div>
    <div *ngIf="!isAPIFailed; else ApiFailedBlock">
        <div class="dialog-header">
            <div>
                Flight Leg ID - {{selectedFlightLeg.legGUID}}
                <span>({{ selectedFlightLeg.departureAirportCode }} - {{selectedFlightLeg.arrivalAirportCode }})</span>
            </div>
            <mat-icon style="align-content: flex-end;" (click)="closeButtonClick()">close</mat-icon>
        </div>
        <div class="boarder-header"></div>
        <div style="padding: 10px;">
        
        
            <div class="mat-expansion-panel-container">
                <mat-expansion-panel expanded="true">
                    <mat-expansion-panel-header>
                        Basic Information
                    </mat-expansion-panel-header>
                    <mat-grid-list [cols]="gridColumnSize" rowHeight="50px" gutterSize="5px">
                        <mat-grid-tile class="grid-class" [colspan]="legGuidColSize" [rowspan]="1">
                            <mat-form-field class="form-field-class" appearance="outline" subscriptSizing="dynamic"
                                floatLabel="always">
                                <mat-label>Leg GUID</mat-label>
                                <input matInput disabled value="{{selectedFlightLeg.legGUID }}">
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile class="grid-class" [colspan]="1" [rowspan]="1">
                            <mat-form-field class="form-field-class" appearance="outline" subscriptSizing="dynamic"
                                floatLabel="always">
                                <mat-label>Leg Status</mat-label>
                                <input matInput disabled value="{{selectedFlightLeg.flightLegStatus }}">
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile class="grid-class" [colspan]="1" [rowspan]="1">
                            <mat-form-field class="form-field-class" appearance="outline" subscriptSizing="dynamic"
                                floatLabel="always">
                                <mat-label>Seat Number</mat-label>
                                <input matInput disabled value="{{selectedFlightLeg.seatNumber}}">
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile class="grid-class" [colspan]="1" [rowspan]="1">
                            <mat-form-field style="width: 100%; font-size:15px ;" appearance="outline" subscriptSizing="dynamic"
                                floatLabel="always">
                                <mat-label>Flight ID(FR 24)</mat-label>
                                <input matInput disabled value="{{selectedFlightLeg.fr24FlightId}}">
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile class="grid-class" [colspan]="1" [rowspan]="1">
                            <mat-form-field style="width: 100%; font-size:15px ;" appearance="outline" subscriptSizing="dynamic"
                                floatLabel="always">
                                <mat-label>Test Flight</mat-label>
                                <input matInput disabled value="{{selectedFlightLeg.testFlight}}">
                            </mat-form-field>
                        </mat-grid-tile>
                    </mat-grid-list>
                </mat-expansion-panel>
            </div>
            <div class="mat-expansion-panel-container">
                <button pButton [disabled]="!selectedFlightLeg.extractedDataAvailable" [loading]="isDownloading_Processed_FSPData" class="button-style" (click)="downloadContent('Pre-Processed_FSPData')">
                    <mat-icon class="matIcon-style"  [hidden]="isDownloading_Processed_FSPData" >file_download</mat-icon> <span>{{ status }}</span>
                </button>
                <span  *ngIf="fileDownloadStatusMap['Pre-Processed_FSPData']" [hidden]="!isDownloading_Processed_FSPData" class="downloaded-progress">&nbsp;&nbsp;({{fileDownloadStatusMap['Pre-Processed_FSPData'].loadedBytes/1048576 |number:'1.2-2'}}/{{fileDownloadStatusMap['Pre-Processed_FSPData'].totalBytes/1048576 | number:'1.2-2'}} mb)</span>
            </div>
            <div class="mat-expansion-panel-container">
                <mat-expansion-panel expanded="true">
                    <mat-expansion-panel-header>
                        Airport Details
                    </mat-expansion-panel-header>
                    <mat-grid-list [cols]="gridColumnSize" rowHeight="50px" gutterSize="5px">
                        <mat-grid-tile class="grid-class" [colspan]="1" [rowspan]="1">
                            <mat-form-field class="form-field-class" appearance="outline" subscriptSizing="dynamic"
                                floatLabel="always">
                                <mat-label>Departure Airport Code</mat-label>
                                <input matInput [value]="selectedFlightLeg.departureAirportCode" disabled>
                                <mat-icon matSuffix [matTooltip]="selectedFlightLeg.departureAirportName" matTooltipClass="multiline-tooltip" class="tooltip-icon">info</mat-icon>
                            </mat-form-field> </mat-grid-tile>
                        <mat-grid-tile class="grid-class" [colspan]="" [rowspan]="1">
                            <mat-form-field class="form-field-class" appearance="outline" subscriptSizing="dynamic"
                                floatLabel="always">
                                <mat-label>Departure Date & Time (Scheduled)</mat-label>
                                <input matInput disabled
                                    value="{{selectedFlightLeg.scheduledDepartureDate ? (selectedFlightLeg.scheduledDepartureDate | timeFormat) :''}}">
                                <mat-icon matSuffix [matTooltip]="timeFormatTooltip" matTooltipClass="multiline-tooltip" class="tooltip-icon">info</mat-icon>
                            </mat-form-field></mat-grid-tile>
                        <mat-grid-tile class="grid-class" [colspan]="1" [rowspan]="1">
                            <mat-form-field class="form-field-class" appearance="outline" subscriptSizing="dynamic"
                                floatLabel="always">
                                <mat-label>Departure Date & Time (Actual)</mat-label>
                                <input matInput disabled
                                    value="{{selectedFlightLeg.actualDepartureDate ? (selectedFlightLeg.actualDepartureDate | timeFormat) :''}}">
                                <mat-icon matSuffix [matTooltip]="timeFormatTooltip" matTooltipClass="multiline-tooltip" class="tooltip-icon">info</mat-icon>
                            </mat-form-field></mat-grid-tile>
                    </mat-grid-list>
                    <mat-grid-list [cols]="gridColumnSize" rowHeight="50px" gutterSize="5px">
                        <mat-grid-tile class="grid-class" [colspan]="1" [rowspan]="1">
                            <mat-form-field class="form-field-class" appearance="outline" subscriptSizing="dynamic"
                                floatLabel="always">
                                <mat-label>Arrival Airport Code</mat-label>
                                <input matInput disabled value="{{selectedFlightLeg.arrivalAirportCode  }}">
                                <mat-icon matSuffix [matTooltip]="selectedFlightLeg.arrivalAirportName" matTooltipClass="multiline-tooltip" class="tooltip-icon">info</mat-icon>
                            </mat-form-field></mat-grid-tile>
                        <mat-grid-tile class="grid-class" [colspan]="1" [rowspan]="1">
                            <mat-form-field class="form-field-class" appearance="outline" subscriptSizing="dynamic"
                                floatLabel="always">
                                <mat-label>Arrival Date & Time (Scheduled)</mat-label>
                                <input matInput disabled
                                    value="{{selectedFlightLeg.scheduledArrivalDate ? (selectedFlightLeg.scheduledArrivalDate | timeFormat) :''}}">
                                <mat-icon matSuffix [matTooltip]="timeFormatTooltip" matTooltipClass="multiline-tooltip" class="tooltip-icon">info</mat-icon>
                            </mat-form-field></mat-grid-tile>
                        <mat-grid-tile class="grid-class" [colspan]="1" [rowspan]="1">
                            <mat-form-field class="form-field-class" appearance="outline" subscriptSizing="dynamic"
                                floatLabel="always">
                                <mat-label>Arrival Date & Time (Actual)</mat-label>
                                <input matInput disabled
                                    value="{{selectedFlightLeg.actualArrivalDate ? (selectedFlightLeg.actualArrivalDate | timeFormat) :''}}">
                                <mat-icon matSuffix [matTooltip]="timeFormatTooltip" matTooltipClass="multiline-tooltip" class="tooltip-icon">info</mat-icon>
                            </mat-form-field></mat-grid-tile>
                    </mat-grid-list>
                </mat-expansion-panel>
            </div>
            <div class="mat-expansion-panel-container">
                <mat-expansion-panel expanded="true">
                    <mat-expansion-panel-header>
                        Flight Details
                    </mat-expansion-panel-header>
                    <mat-grid-list [cols]="gridColumnSize" rowHeight="50px" gutterSize="10px">
                        <mat-grid-tile class="grid-class" [colspan]="1" [rowspan]="1">
                            <mat-form-field class="form-field-class" appearance="outline" subscriptSizing="dynamic"
                                floatLabel="always">
                                <mat-label>Airline Name</mat-label>
                                <input matInput disabled value="{{selectedFlightLeg.airlineName }}">
                            </mat-form-field> </mat-grid-tile>
                        <mat-grid-tile class="grid-class" [colspan]="1" [rowspan]="1">
                            <mat-form-field class="form-field-class" appearance="outline" subscriptSizing="dynamic"
                                floatLabel="always">
                                <mat-label>Flight Number</mat-label>
                                <input matInput disabled value="{{selectedFlightLeg.flightNumber }}">
                            </mat-form-field></mat-grid-tile>
                        <mat-grid-tile class="grid-class" [colspan]="1" [rowspan]="1">
                            <mat-form-field class="form-field-class" appearance="outline" subscriptSizing="dynamic"
                                floatLabel="always">
                                <mat-label>Aircraft Model</mat-label>
                                <input matInput disabled value="{{selectedFlightLeg.aircraftModel }}">
                            </mat-form-field></mat-grid-tile>
                        <mat-grid-tile class="grid-class" [colspan]="1" [rowspan]="1">
                            <mat-form-field class="form-field-class" appearance="outline" subscriptSizing="dynamic"
                                floatLabel="always">
                                <mat-label>Manufacturer</mat-label>
                                <input matInput disabled value="{{selectedFlightLeg.aircraftManufacturer  }}">
                            </mat-form-field></mat-grid-tile>
                        <mat-grid-tile class="grid-class" [colspan]="1" [rowspan]="1">
                            <mat-form-field class="form-field-class" appearance="outline" subscriptSizing="dynamic"
                                floatLabel="always">
                                <mat-label>Tail Number</mat-label>
                                <input matInput disabled value="{{selectedFlightLeg.tailNumber }}">
                            </mat-form-field></mat-grid-tile>
                    </mat-grid-list>
        
                    <div class="baggage-header">Baggage Details</div>
                    <mat-grid-list [cols]="gridColumnSize" rowHeight="50px" gutterSize="10px">
                        <mat-grid-tile class="grid-class" [colspan]="1" [rowspan]="1">
                            <mat-form-field class="form-field-class" appearance="outline" subscriptSizing="dynamic"
                                floatLabel="always">
                                <mat-label>Baggage Measurement Unit</mat-label>
                                <input matInput disabled value="{{selectedFlightLeg.baggageMeasurementUnit }}">
                            </mat-form-field> </mat-grid-tile>
                        <mat-grid-tile class="grid-class" [colspan]="1" [rowspan]="1">
                            <mat-form-field class="form-field-class" appearance="outline" subscriptSizing="dynamic"
                                floatLabel="always">
                                <mat-label>Baggage Length</mat-label>
                                <input matInput disabled value="{{selectedFlightLeg.baggageLength }}">
                            </mat-form-field></mat-grid-tile>
                        <mat-grid-tile class="grid-class" [colspan]="1" [rowspan]="1">
                            <mat-form-field class="form-field-class" appearance="outline" subscriptSizing="dynamic"
                                floatLabel="always">
                                <mat-label>Baggage Width</mat-label>
                                <input matInput disabled value="{{selectedFlightLeg.baggageWidth }}">
                            </mat-form-field></mat-grid-tile>
                        <mat-grid-tile class="grid-class" [colspan]="1" [rowspan]="1">
                            <mat-form-field class="form-field-class" appearance="outline" subscriptSizing="dynamic"
                                floatLabel="always">
                                <mat-label>Baggage Height</mat-label>
                                <input matInput disabled value="{{selectedFlightLeg.baggageHeight }}">
                            </mat-form-field></mat-grid-tile>
                    </mat-grid-list>
                </mat-expansion-panel>
            </div>
            <div class="mat-expansion-panel-container" *ngIf="isAdminOrSuperAdmin">
                <mat-expansion-panel expanded="true">
                    <mat-expansion-panel-header>
                        Marshalling Details
                    </mat-expansion-panel-header>
                    <div class="marshall-details" *ngIf="selectedFlightLeg.flightLegStatus == 'Created' ; else elseBlock">
                        <img style="width: 6em; height: 6em;" src="../../../../../assets/boeing/open_box_image.png">
                        Marshalling data still in processing,
                        <div>
                            No data to show yet.
                        </div>
                    </div>
                    <ng-template #elseBlock>
                        <div class="marshall-table-header" style="margin-top: 0px;">
                            Finalized ADS-B Mode S Data
                        </div>
        
                        <table class="marshall-table">
                            <thead>
                                <th style="width: 25%;">File Name</th>
                                <th style="width: 55%;">System Message</th>
                                <th style="width: 10%;">Status</th>
                                <th style="width: 10%;">Actions</th>
                            </thead>
                            <tbody [class]="{'row-error': marshallingDataSet[0].dataSourceStatus.includes('Error')}">
                                <td> {{marshallingDataSet[0].dataSourceStatus == 'Available' ? selectedFlightLeg.fr24FlightId +'_Finalized_' + selectedFlightLeg.legGUID+'.CSV':'NIL' }} </td>
                                <td  [innerHTML]="marshallingDataSet[0].message ? marshallingDataSet[0].message : 'NIL'" class="system-message-class"> </td>
                                <td> {{marshallingDataSet[0].dataSourceStatus}}</td>
                                <td>
                                    <div *ngIf="!marshallingDataSet[0].dataSourceStatus.includes('Available'); else thenBlock" >
                                        <div *ngIf="!marshallingDataSet[0].dataSourceStatus.includes('In Progress'); else syncInProgressBlock">
                                            <img class="action-icon"  (click)="triggerADSBFetch(selectedFlightLeg)" style="height: 1.5em; width: 1.5em;"
                                            src="../../../../../assets/boeing/cloud_sync.png">
                                        </div>
                                        <ng-template #syncInProgressBlock>
                                            <div *ngIf="isADSBFetchAPIInProgress">
                                                <p-progressSpinner   [style]="{width: '1.5em', height: '1.5em'}" class="custom-spinner" ariaLabel="loading" />
                                            </div>
                                            <div *ngIf="!isADSBFetchAPIInProgress">
                                                <img style="height: 1.5em; width: 1.5em;"src="../../../../../assets/boeing/tick-icon.svg">
                                            </div>
                                        </ng-template>
                                    </div>
                                    <ng-template #thenBlock>
                                            <div *ngIf="isDownloading_ADSB_Data; else downloadBlock">
                                                <img src="../../../../../assets/boeing/download-processing.png" alt="upload" pTooltip="({{fileDownloadStatusMap['ADSB_Data'].loadedBytes/1048576 |number:'1.2-2'}}/{{fileDownloadStatusMap['ADSB_Data'].totalBytes/1048576 | number:'1.2-2'}} mb)" tooltipPosition="bottom">
                                            </div>
                                        </ng-template>
                                    <ng-template #downloadBlock>
                                        <div >
                                            <mat-icon class="action-icon"  (click)="downloadContent('ADSB_Data')">file_download</mat-icon>
                                        </div>
                                    </ng-template>   
                                </td>
                            </tbody>
                        </table>
        
                        <div class="marshall-table-header">
                            Finalized FSP Data
                        </div>
        
                        <table class="marshall-table">
                            <thead>
                                <th style="width: 25%;">File Name</th>
                                <th style="width: 55%;">System Message</th>
                                <th style="width: 10%;">Status</th>
                                <th style="width: 10%;">Actions</th>
                            </thead>
                            <tbody [class]="{'row-error': marshallingDataSet[1].dataSourceStatus.includes('Error')}">
                                <td> {{marshallingDataSet[1].dataSourceStatus == 'Available' ? 'FSP_Finalized_' + selectedFlightLeg.legGUID+'.zip':'NIL'}}</td>
                                <td  [innerHTML]="marshallingDataSet[1].message ? marshallingDataSet[1].message : 'NIL'" class="system-message-class"> </td>
                                <td> {{marshallingDataSet[1].dataSourceStatus}} </td>
                                <td >
                                    <div *ngIf="marshallingDataSet[1].dataSourceStatus.includes('Error') || marshallingDataSet[1].dataSourceStatus =='Unavailable'; else thenBlock ">
                                        <img  class="action-icon"  style="height: 1.5em"
                                            src="../../../../../assets/boeing/cloud_sync.png">
                                    </div>
                                    <ng-template #thenBlock>
                                        <div *ngIf="isDownloading_Finalized_FSPData; else downloadBlock">
                                            <img src="../../../../../assets/boeing/download-processing.png" alt="upload">
                                        </div>
                                    </ng-template>
                                <ng-template #downloadBlock>
                                    <div >
                                        <mat-icon class="action-icon"  (click)="downloadContent('Finalized_FSPData')">file_download</mat-icon>
                                    </div>
                                </ng-template>   
                                </td>
                            </tbody>
                        </table>
        
                        <div class="marshall-table-header">
                            Finalized External Data
                        </div>
        
                        <table class="marshall-table">
                            <thead>
                                <th style="width: 25%;">File Name</th>
                                <th style="width: 55%;">System Message</th>
                                <th style="width: 10%;">Status</th>
                                <th style="width: 10%;">Actions</th>
                            </thead>
                            <tbody [class]="{'row-error': marshallingDataSet[2].dataSourceStatus.includes('Error')}">
                                <td> {{ marshallingDataSet[2].dataSourceStatus =='Available'? 'External_Finalized_' + selectedFlightLeg.legGUID+'.zip':'NIL'}} </td>
                                <td  [innerHTML]="marshallingDataSet[2].message ? marshallingDataSet[2].message : 'NIL'" class="system-message-class"> </td>
                                <td> {{ marshallingDataSet[2].dataSourceStatus}} </td>
                                <td >
                                    <div *ngIf=" marshallingDataSet[2].dataSourceStatus.includes('Error') ||  marshallingDataSet[2].dataSourceStatus =='Unavailable'; else thenBlock ">
                                        <img class="action-icon"  style="height: 1.5em"
                                            src="../../../../../assets/boeing/cloud_sync.png">
                                    </div>
                                    <ng-template #thenBlock>
                                        <div *ngIf="isDownloading_External_Data; else downloadBlock">
                                            <img src="../../../../../assets/boeing/download-processing.png" alt="upload">
                                        </div>
                                    </ng-template>
                                <ng-template #downloadBlock>
                                    <div >
                                        <mat-icon class="action-icon"  (click)="downloadContent('External_Data')" >file_download</mat-icon>
                                    </div>
                                </ng-template>
                                </td>
                            </tbody>
                        </table>
        
                        <div *ngIf="(marshallingDataSet[0].dataSourceStatus=='Available' && marshallingDataSet[1].dataSourceStatus=='Available' )">
                            <div class="marshall-table-header">
                                Marshalled Data
                            </div>
        
                            <table class="marshall-table">
                                <thead>
                                    <th style="width: 25%;">File Name</th>
                                    <th style="width: 55%;">System Message</th>
                                    <th style="width: 10%;">Status</th>
                                    <th style="width: 10%;">Actions</th>
                                </thead>
                                <tbody [class]="{'row-error': marshallingDataSet[3].dataSourceStatus.includes('Error')}">
                                    <td> {{ marshallingDataSet[3].dataSourceStatus =='Available'? 'Processed_'+ selectedFlightLeg.legGUID+'.zip' :'NIL'}} </td>
                                    <td  [innerHTML]="marshallingDataSet[3].message ? marshallingDataSet[3].message : 'NIL'" class="system-message-class"> </td>
                                    <td> {{ marshallingDataSet[3].dataSourceStatus}} </td>
                                    <td>
                                        <div *ngIf="marshallingDataSet[3].dataSourceStatus.includes('Error') || marshallingDataSet[3].dataSourceStatus =='Unavailable'; else thenBlock ">
                                            <img class="action-icon"  style="height: 1.5em"
                                                src="../../../../../assets/boeing/cloud_sync.png">
                                        </div>
                                        <ng-template #thenBlock>
                                            <div style="display:flex; flex-direction: row; align-items: center;">
                                                <mat-icon class="action-icon"   (click)="downloadContent('Marshalled_Data')" >file_download</mat-icon>
                                                <img class="action-icon"  style="height: 1.5em; margin-left: 1em;"
                                                    src="../../../../../assets/boeing/mail.png">
                                            </div>
                                        </ng-template>
                                    </td>
                                </tbody>
                            </table>
                        </div>
                    </ng-template>
                </mat-expansion-panel>
            </div>
        </div>
    </div>
    <ng-template #ApiFailedBlock>
        <div class="dialog-header">
            <div>
                Flight Leg ID - {{selectedFlightLeg.legGUID}}
                <span>({{ selectedFlightLeg.departureAirportCode }} - {{selectedFlightLeg.arrivalAirportCode }})</span>
            </div>
            <mat-icon style="align-content: flex-end;" (click)="closeButtonClick()">close</mat-icon>
        </div>
        <div class="boarder-header"></div>
        <div>
            <app-internal-error></app-internal-error>
        </div>
    </ng-template>
</div>
 

