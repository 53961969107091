import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TermsofUse } from 'src/app/models/terms-of-use-interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TermsOfUseService {

  constructor(private http: HttpClient) { }


  
  getTermsofUse(): Observable<TermsofUse> {
    return this.http.get<TermsofUse>(`${environment.baseUrl}/api/app/termsOfUse`);
  }
}
