import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, filter, Observable, tap, throwError } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
import { wssoAuthCodeFlowConfig } from 'src/app/auth.config';

@Injectable({
  providedIn: 'root',
})
export class SkyappJWTAuthService {
  private tokenKey = 'skyappJWT';

  private accessTokenValidSubject = new BehaviorSubject<boolean>(this.hasValidAccessToken());
  private skyappTokenValidSubject = new BehaviorSubject<boolean>(this.hasValidSkyAppToken());

  private userRolesSubject = new BehaviorSubject<string[]>([]);

  private decodedToken: any;

  constructor(
    private http: HttpClient,
    private jwtHelper: JwtHelperService,
    private oauthService: OAuthService,
    private router: Router
  ) { }

  getSkyAppToken(): string | null {
    return localStorage.getItem(this.tokenKey);
  }

  setSkyAppToken(token: string): void {
    localStorage.setItem(this.tokenKey, token);
    this.skyappTokenValidSubject.next(true);
    //we are pushing new roles from token to the roles subject
    this.decodedToken = this.jwtHelper.decodeToken(token);
    const roles = this.decodedToken?.groups || [];
    this.userRolesSubject.next(roles);
  }

  // Method to decode and retrieve the token
  getDecodedToken(skyappToken: string): any {
    return this.jwtHelper.decodeToken(skyappToken);
  }


  getUserRoles(): Observable<string[]> {
    let token =this.getSkyAppToken();
    if(token){
      //we are pushing new roles from token to the roles subject
      this.decodedToken = this.getDecodedToken(token);
      const roles = this.decodedToken?.groups || [];
      this.userRolesSubject.next(roles);
    }
    return this.userRolesSubject.asObservable();
  }




  isAdminOrSuperAdmin(): boolean {
    // Check if decodedToken exists, if not fetch it
    if(!this.decodedToken){
      let token = this.getSkyAppToken();
      if(token!= null){
        this.decodedToken = this.getDecodedToken(token);
      }
    }
    // check if the decoded payload has ADMIN or SUPERADMIN roles
    return this.decodedToken && this.decodedToken.groups &&
    (this.decodedToken.groups.includes("ADMIN") || this.decodedToken.groups.includes("SUPERADMIN"));
  }

  removeSkyAppToken(): void {
    localStorage.removeItem(this.tokenKey);
  }



  authenticate() {
    console.log('Initializing OAuth2 configuration...');
    this.oauthService.configure(wssoAuthCodeFlowConfig);
  
    console.log('Enabling automatic silent refresh...');
    this.oauthService.setupAutomaticSilentRefresh();
  
    console.log('Checking session storage for tokens and OAuth2 callback...');
    this.oauthService.loadDiscoveryDocumentAndTryLogin()
      .then(() => this.handleInitialLogin())
      .catch(error => {
        console.error('Error during login:', error);
        this.oauthService.initCodeFlow();  // Always trigger the code flow for re-authentication
      });
  
    // Handle silent refresh errors in a centralized way
    this.handleSilentRefreshError();
  }
  
  private handleInitialLogin() {
    if (this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken()) {
      console.log('User authenticated by fedProxy...');
      this.accessTokenValidSubject.next(true);
      // Check if We have valid Skyapp token
      if (this.hasValidSkyAppToken()) {
        console.log('Valid SkyApp token found.');
        this.skyappTokenValidSubject.next(true);
        this.router.navigate(['/home']);
      } else {
        this.generateSkyAppTokenAndRedirect();
      }
    } else {
      this.trySilentRefresh();
    }
  }
  
  private trySilentRefresh() {
    console.log('User not authenticated, attempting silent refresh...');
    this.oauthService.silentRefresh()
      .then(() => {
        console.log('Silent refresh successful...');
        this.accessTokenValidSubject.next(true);
        this.generateSkyAppTokenAndRedirect();
      })
      .catch(error => {
        console.error('Silent refresh failed:', error);
        this.oauthService.initCodeFlow();  // Trigger the code flow if silent refresh fails
      });
  }
  
  private generateSkyAppTokenAndRedirect() {
    console.log('Fetching SkyApp token...');
    this.validateUserAndGenerateJWTv2()
      .subscribe(
        (tokenResponse) => {
          console.log('SkyApp token retrieved successfully.');
          this.skyappTokenValidSubject.next(true);
          this.router.navigate(['/home']);
        },
        (error) => {
          console.error('Error fetching SkyApp token:', error);
          this.skyappTokenValidSubject.next(false);
        }
      );
  }
  
  private handleSilentRefreshError() {
    this.oauthService.events
      .pipe(filter(e => e.type === 'silent_refresh_error'))
      .subscribe(() => {
        console.log('Silent refresh error occurred...');
        this.oauthService.initCodeFlow();  // Automatically redirect to re-authentication
      });
  }
  


  // authenticate() {
  //   console.log('initializing oauth2 configuration...');
  //   this.oauthService.configure(wssoAuthCodeFlowConfig);
  //   console.log('enable automatic silent refresh...');
  //   this.oauthService.setupAutomaticSilentRefresh();
  //   console.log('check session storage for tokens and for oauth2 call back...');
  //   this.oauthService.loadDiscoveryDocumentAndTryLogin().then(() => {
  //     // Check if we have valid tokens from fedProxy server
  //     if (this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken()) {
  //       console.log('User authenticated by fedProxy...');
  //       this.accessTokenValidSubject.next(true);
  //       // Check if We have valid Skyapp token
  //       if (this.hasValidSkyAppToken()) {
  //         // if we have all valid tokens then publish that we have valid token to observers of tokenValidityObserver()
  //         console.log('Has valid skyapp token');
  //         this.skyappTokenValidSubject.next(true);
  //         this.router.navigate(['/home']);
  //       }
  //       // if the skyapp token is not valid then initiate a call to get new skyapp token
  //       else {
  //         this.validateUserAndGenerateJWTv2()
  //           .subscribe(
  //             (tokenResponse) => {
  //               this.skyappTokenValidSubject.next(true);
  //               this.router.navigate(['/home']);
  //             },
  //             (error) => {
  //               this.skyappTokenValidSubject.next(false);
  //               console.error(error);
  //             }
  //           );
  //       }
  //     } else {
  //       console.log('User not authenticated... try silent refresh...');
  //       this.oauthService.silentRefresh().then((response) => 
  //       {
  //         this.accessTokenValidSubject.next(true);
  //         this.validateUserAndGenerateJWTv2()
  //           .subscribe(
  //             (tokenResponse) => {
  //               this.skyappTokenValidSubject.next(true);
  //               this.router.navigate(['/home']);
  //             },
  //             (error) => {
  //               this.skyappTokenValidSubject.next(false);
  //               console.error(error);
  //               this.router.navigate(['/home']);
  //             }
  //           );
  //       }).catch((error) => {
  //         console.log("silentRefresh error#################", error);

  //       });
  //     }
  //   }).catch(error => {
  //     console.log("loadDiscoveryDocumentAndTryLogin error###########", error);
  //   });

  //   this.oauthService.events
  //     .pipe(filter((e) => e.type == 'silent_refresh_error'))
  //     .subscribe((_) => {
  //       if (this.oauthService.getAccessToken()) {
  //         // if we got a silent refresh error and we have an access token, then we need to warn the user
  //         console.log('Need page redirect to authenticate...');
  //         console.log(Date.now());

  //         let confirmed = confirm(
  //           'Credentials stored in this browser are no longer good and silent refresh has failed. Click OK to re-authenticate...'
  //         );
  //         if (confirmed) {
  //           this.oauthService.initCodeFlow();
  //         }
  //       }
  //       else {
  //         // This code will automatically redirect the user to IDP initially when no valid tokens
  //         //  are available. This is the entry point for fresh login
  //         this.oauthService.initLoginFlow();
  //       }
  //     });
  // }

  logout(): void {
    this.oauthService.logOut();
    this.removeSkyAppToken();
    this.accessTokenValidSubject.next(false);
    this.skyappTokenValidSubject.next(false);
  }

  validateUserAndGenerateJWT(bemsId: string): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('bemsId', bemsId);
    queryParams = queryParams.append('userRole', 'SUPERADMIN');
    return this.http
      .get(`${environment.baseUrl}/api/user/validate`, { params: queryParams })
      .pipe(
        tap((response: any) => {
          console.log(response);
          if (response && response.accessToken) {
            this.setSkyAppToken(response.accessToken);
          }
        }),
        catchError((error) => {
          console.error('Login error:', error);
          return throwError(error);
        })
      );
  }

  validateUserAndGenerateJWTv2(): Observable<any> {
    return this.http
      .post(`${environment.baseUrl}/api/user/validate/v2`, {})
      .pipe(
        tap((response: any) => {
          console.log(response);
          if (response && response.accessToken) {
            this.setSkyAppToken(response.accessToken);
          }
        }),
        catchError((error) => {
          console.error('Login error:', error);
          return throwError('Login failed.');
        })
      );
  }

  // Generic method for claims
  getClaim(claimNames: string[]): any {
    let claims = this.oauthService.getIdentityClaims();
    if (!claims) {
      return null; // handle the case where claims are not available
    }

    let result: { [key: string]: any } = {};
    claimNames.forEach(claimName => {
      result[claimName] = claims[claimName];
    });
    return result;
  }


  getAccesTokenValidity(): BehaviorSubject<boolean> {
    return this.accessTokenValidSubject;
  }

  getSkyAppTokenValidity(): BehaviorSubject<boolean> {
    return this.skyappTokenValidSubject;
  }

  setAccesTokenValidity(value: boolean): void {
    this.accessTokenValidSubject.next(value);
  }

  setSkyAppTokenValidity(value: boolean): void {
    this.skyappTokenValidSubject.next(value);
  }

  areAllTokenValid(): boolean {
    if (
      this.oauthService.hasValidAccessToken() &&
      this.oauthService.hasValidIdToken() &&
      this.hasValidSkyAppToken()
    ) {
      return true;
    } else {
      return false;
    }
  }

  hasValidSkyAppToken(): boolean {
    const skyappToken = this.getSkyAppToken();
    if (skyappToken) {
      try {
        this.decodedToken = this.getDecodedToken(skyappToken);
        if (this.decodedToken && !this.jwtHelper.isTokenExpired(skyappToken)) {
          // Token is not expired
          return true;
        } else {
          console.log('token expired');
          return false;
        }
      } catch (error) {
        console.error('Token decoding error:', error);
        return false;
      }
    } else {
      return false;
    }
  }


  hasValidAccessToken(): boolean {
    return this.oauthService.hasValidAccessToken();
  }


}
