import {Component,EventEmitter,Inject,Input,OnInit,Output} from '@angular/core';
import { environment } from 'src/environments/environment';
import { FlightLeg } from 'src/app/models/flight-leg-interface';
import { FileUploadService} from 'src/app/services/file-upload/file-upload.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UploadFlightLegDialogComponent } from 'src/app/modules/home/components/upload-flight-leg-dialog/upload-flight-leg-dialog.component';
import { FileUploadContainer } from 'src/app/models/upload-file-interface';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnInit {
  @Input() flightLeg!: FlightLeg;   // selected Flight leg from parent component
  @Input() uploadType!: string; 
  @Input() fileDetail!: FileUploadContainer;            // File details from parent component
  @Output() fileChange = new EventEmitter<any>(); // use of triggered the function in parent component
  selectedFiles!: FileList;
  files: any[] = [];
  filesSelected: File[] = [];
  uploadFilesContainer: any[] = [];
  selectedFile!: File;
  uploadStatus: FileUploadContainer | null = null;
  sasToken: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UploadFlightLegDialogComponent>,
    public uploadService: FileUploadService
  ) {}

  ngOnInit(): void {
    this.getFileStatusAndProgress();
  }

  async getSAStoken(): Promise<string> {
    let bloburl = `${this.flightLeg.bemsId}/${this.fileDetail.fileName}`;
    let sasToken = await this.uploadService
      .generateSasToken('UPLOAD',environment.rawDataContainerName,bloburl)
      .toPromise();
    sasToken = `?${sasToken.token}`;
    console.log('token recevied:', sasToken);
    return sasToken;
  }


  async uploadFile() {
    let token = await this.getSAStoken();
    let identifier = `${this.flightLeg.legGUID}-UPLOAD-${this.uploadType}-${this.fileDetail.fileName}`;
    this.uploadService.upload(this.fileDetail,this.flightLeg,token,this.dialogRef,identifier);
    // this.getFileStatusAndProgress();
  }

  async cancelUpload(): Promise<void> {
    let identifier = `${this.flightLeg.legGUID}-UPLOAD-${this.uploadType}-${this.fileDetail.fileName}`;
    await this.uploadService.cancelUpload(identifier);
  }

  resetStage() {
    let identifier = `${this.flightLeg.legGUID}-UPLOAD-${this.uploadType}-${this.fileDetail.fileName}`;
    this.uploadService.removeUploadStatus(identifier);
    this.fileChange.emit();
  }

  getFileStatusAndProgress(){
    this.uploadService.getFileProgressUpdates().subscribe((status) => {
      let identifier = `${this.flightLeg.legGUID}-UPLOAD-${this.uploadType}-${this.fileDetail.fileName}`;
      if (status[identifier]) {
        this.fileDetail.progress = status[identifier].progress;
        this.fileDetail.state = status[identifier].state;
      }
    });
  }
}
