import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { MenuModule } from 'primeng/menu';
import { SkyappJWTAuthService } from 'src/app/services/skyapp-jwt-auth/skyapp-jwtauth.service';
import { User } from 'src/app/models/user-interface';
import { MatDialog } from '@angular/material/dialog';
import { TermsOfUseService } from 'src/app/services/terms-of-use/terms-of-use.service';
import { environment } from 'src/environments/environment';
import { TimeFormatService } from 'src/app/services/timeformat/time-format.service';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  items: MenuItem[] = [];
  userItems: MenuItem[] = [];
  vectorItems: MenuItem[] = [];
  timeZoneItems: MenuItem[]=[];
  isAdminOrSuperAdmin:boolean=false;
  userDetails!: User;
  localStorage = localStorage;
  processedTermsOfUse: string = '';
  display: boolean = false;
  constructor(
    private skyAppJwtAuthService: SkyappJWTAuthService,
    private router: Router,
    private dialog: MatDialog,
    private termsoOfUseService: TermsOfUseService,
    private timeFormatService : TimeFormatService,
    private cdr: ChangeDetectorRef
  ) {}
  ngOnInit() {



    this.isAdminOrSuperAdmin = this.skyAppJwtAuthService.isAdminOrSuperAdmin();
    let claims = this.skyAppJwtAuthService.getClaim([
      'givenName',
      'surname',
      'mainEmail',
      'sub',
    ]);
    if(claims){
      this.userDetails = this.mapResponseToUser(claims);
    }
   
    this.vectorItems = [
      {
        label: 'User Configuration',
      },
      {
        label: 'Survey Configuration',
      },
      {
        label: 'Internal Configuration',
      },
      {
        label: 'Feature Configuration',
      },
    ];

    this.timeZoneItems = [
      {
        label: 'Show Airport Local Time',
        command: () => {
          this.timeFormatService.setFormat('local');
          this.updateTimeZoneItems('local');
        }
      },
      {
        label: 'Show all times in UTC',
        command: () =>{ 
          this.timeFormatService.setFormat('utc');
          this.updateTimeZoneItems('utc');
        }
      }
    ];

    


    this.userItems = [
      {
        label: (this.userDetails)
          ? '<div class="main-item">' +
            this.userDetails.firstName +
            ' ' +
            this.userDetails.lastName +
            ' </div><div class="sub-item">' +
            this.userDetails.email +
            '</div>'
          : '',
        escape: false,
        items: [
          {
            label: 'Your MBF account',
          },
          {
            label: 'Privacy Policy',
            url: 'https://www.boeing.com/privacy/employment-related-privacy-notice.html',
          },
          {
            label: 'Terms of Use',
            command: () => this.loadTermsofUseDialog(),
          },
          {
            label: 'Contact Us',
            url: 'mailto:DL-BISESkyApp@exchange.boeing.com',
          },
          {
            label: 'Sign Out',
            icon: 'pi pi-fw pi-sign-out',
            escape: false,
            // command: () => this.navigateTo('logout'),
            command: () => {
              window.location.href = `${environment.wssoIssuer}/idp/startSLO.ping?TargetResource=${environment.baseUrl}/logout`;
            },
            styleClass: 'signOut',
            style: { 'background-color': '#263746', color: 'white' },
          },
        ],
      },

      {
        label:
          '<div class="sub-item">Copyrights © 2024 Boeing. All rights reserved.</div>',
        escape: false,
      },
    ];

    // Subscribe to the time format changes
    this.timeFormatService.currentFormat$.subscribe((format) => {
      this.updateTimeZoneItems(format);          
    });
  }

  getFormatFromLabel(label: string | undefined): string {
    if(label!=undefined){
      if (label.includes('Show Airport Local Time')) {
        return 'local';
      } else if (label.includes('Show all times in UTC')) {
        return 'utc';
      }
      return '';
    }
    return '';
   
  }

  // getFormatFromLabel(label: string | undefined): string {
  //   if(label!=undefined){
  //     if (label.includes('Local')) {
  //       return 'local';
  //     } else if (label.includes('UTC')) {
  //       return 'utc';
  //     }
  //     return '';
  //   }
  //   return '';
   
  // }


  updateTimeZoneItems(selectedFormat: string) {

    this.timeZoneItems = this.timeZoneItems.map(item => ({
      ...item,
      label: selectedFormat === this.getFormatFromLabel(item.label) 
        ? `${item.label?.replace(' ✓', '')} ✓` 
        : item.label?.replace(' ✓', '')
    }));
  }

  mapResponseToUser(response: any): User {
    return {
      userId: 0, // Assuming userId needs to be initialized
      firstName: response.givenName,
      lastName: response.surname,
      email: response.mainEmail,
      bemsId: response.sub,
      isAdmin: false, // Assuming this needs to be initialized
    };
  }

  loadTermsofUseDialog() {
    this.termsoOfUseService.getTermsofUse().subscribe((termsOfUseData) => {
      this.processedTermsOfUse = termsOfUseData.termsofUse.replace(
        '[TrainingNamePlaceholder]',
        `<a href="${termsOfUseData.trainingURL}" target="_blank" class="custom-link">${termsOfUseData.trainingName} <i class="pi pi-external-link"></i></a>`
      );
      this.display = true;
    });
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  closeDialog(): void {
    this.display = false;
  }
}
